<template>
  <div class="pay">
    <div class="pay-conf-container" v-show="isShow">
      <div class="pay-conf-title">支付配置</div>
      <ul class="pay-conf-item">
        <li class="pay-conf-item-detail" v-for="tab in tabs"
            :class="{active:  $route.name.indexOf(tab.value) !== -1}"
            @click="setActive(tab.value)"> {{tab.name}}
        </li>
      </ul>
    </div>
    <div class="pay-detail-container" :class="isShow? 'padding': ''">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

  export default {
    name: '',
    components: {
    },
    mounted: function () {
    },
    watch: {
      $route(to, from) {
        let isShow = false
        this.tabs.forEach(item => {
          if (to.name === item.value) {
            isShow = true
          }
        })
        this.isShow = isShow
      }
    },
    data: function () {
      return {
        isShow: true,
        tabs: [
          {name: '支付宝支付', value: 'collection.quickPay.ali'},
          {name: '微信支付', value: 'collection.quickPay.wx'},
        ]
      }
    },
    methods: {
      setActive(val) {
       this.$router.push({name: val})
      }
    },
  }
</script>

<style lang="scss">
  .pay {
    @include set_bg_color($background-color-light, $background-color-dark);
    border-radius: $border-radius;
    display: flex;

    .pay-conf-container {
      width: 144px;
      padding-right: $padding;
      border-right: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);

      .pay-conf-title {
        width: 100%;
        text-align: center;
        @include set_font_color($_theme_color, $--font-color-dark);
        @include set_bg_color(#eef8ff, #454954);
        padding: 4px 0;
        border-radius: $border-radius;
        margin-bottom: $padding;
      }

      .pay-conf-item {
        padding-left: $padding-8;

        .active {
          color: $_theme_color;
        }

        &-detail {
          cursor: pointer;
          margin-bottom: $padding-8;
        }

        &-detail:hover {
          color: $_theme_color;
        }
      }
    }

    .pay-detail-container {
      flex: 1;
    }
    .padding{
      padding-left: $padding;
    }
  }
</style>
